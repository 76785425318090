@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/colors';
@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/gradients';
@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/layout';

.container {
    background-color: $base-background-color;
    // height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    padding: spacing(3, -1px) spacing(5) spacing(3);

    @include media-query(mobile) {
        // height: 130px;
        padding: spacing(3, -1px) spacing(3) spacing(1);
    }
}

.contentWrapper {
    margin: 0 auto;
    max-width: 688px;
    width: 100%;
    padding-top: 50px;

    &:only-child {
        padding-top: 25px;
        margin: auto;
    }
}

.bar {
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background-image: $gradient-all-statuses;
}

.valueLabel {
    color: $base-text-color;
    font-size: 12px;
    font-weight: 300;
    padding-top: spacing(1, -3px);
}

.bold {
    font-size: 14px;
    font-weight: 500;
    margin-left: spacing(1, -4px);

    &:first-child {
        margin-left: 0;
        margin-right: spacing(1, -4px);
    }
}

.barWrapper {
    width: 100%;
    position: relative;
}

.barValue {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    font-size: px-to-em(24);
    font-weight: bold;
    bottom: 3px;
    position: absolute;
    transform: translateX(-50%);
}

.valueWrapper {
    width: 100%;
    position: absolute;
    bottom: -8px;
}

.arrow {
    color: $base-text-color-inverted;
    margin-top: -7px;

    svg {
        width: 16px;
        path {
            fill: currentColor;
        }
    }
}

.labelWrapper {
    display: flex;
    justify-content: space-between;
}

.title {
    margin: 0 auto 0;
    color: #10264d !important;
    text-align: center;
    z-index: 10;
    line-height: 1.23;
    max-width: 688px;
    font-size: 24px;
    width: 100%;
    @include media-query(mobile) {
        font-size: 18px;
    }
}
