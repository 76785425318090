@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/layout';

.information {
    margin-top: spacing(3, -2px);
}

.infoTitle {
    margin-bottom: spacing(1);
}

.tipsTable {
    margin-top: spacing(5);
}

.buttons {
    margin-top: spacing(5);

    & > * + * {
        margin-top: spacing(1);
    }
}
