@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/layout';

.container {
    display: block;
}

.cloud {
    position: absolute;
}

.good {
    top: 0;
    right: 0;
    width: 257px;
    height: 155px;
    @include media-query(mobile) {
        right: -80px;
    }
}

.moderate1 {
    top: 0;
    left: 0;
    width: 224px;
    height: 240px;

    @include media-query(mobile) {
        left: -82px;
    }
}

.moderate2 {
    left: 0;
    bottom: 0;
    width: 574px;
    height: 248px;

    @include media-query(mobile) {
        width: auto;
        height: 375px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.bad1 {
    top: 0;
    left: 0;
    width: 339px;
    height: 386px;

    @include media-query(mobile) {
        left: -24px;
        top: -70px;
        width: 84.1%;
        height: auto;
        min-width: 339px;
        max-width: 490px;
    }
}

.bad2 {
    width: 285px;
    height: 248px;
    left: 0;
    bottom: -60px;

    @include media-query(mobile) {
        bottom: 0;
    }
}

.bad3 {
    right: 0;
    top: 244px;
    width: 163px;
    height: 380px;

    @include media-query(mobile) {
        top: 48px;
        right: -30px;
    }
}
