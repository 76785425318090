@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/layout';
.sensor {
    --sensor-size: 400px;
    --y-rotate: -9deg;
    --x-rotate: 5deg;
    --border: 40px;
    width: var(--sensor-size);
    height: var(--sensor-size);
    min-width: var(--sensor-size);
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    border-radius: var(--border);
    padding: var(--border);
    transition: transform 0.6s ease-out 0.8s;
    transform-style: preserve-3d;
    transform: rotate3d(0, 1, 0, 0) rotate3d(1, 0, 0, 0);
    border: #cfcfcf solid 2px;
    margin-top: auto;
}

.loaded {
    transform: rotate3d(0, 1, 0, var(--y-rotate)) rotate3d(1, 0, 0, var(--x-rotate)) !important;
}

.sensor:after {
    content: '';
    border-radius: var(--border);
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #c8c8c8;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -2px rgba(0, 0, 0, 0.2);
    transform: translateZ(-120px);
    border: #b6b6b6 solid 2px;
}

.sensor:before {
    content: '';
    border-radius: var(--border);
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 101%;
    height: 101%;
    background: #c8c8c8;
    transform: translateZ(-60px);
}

.sensor_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 100;
    height: 30px;
}

.sensor_header h2 {
    color: #10264d;
    font-weight: 300;
    font-size: 24px;
    vertical-align: top;
    line-height: 100%;
}

.sensor_header svg {
    width: 87px;
}

.sensor_main {
    display: flex;
    padding: calc(var(--sensor-size) / 10);
    position: relative;
    align-items: center;
    width: 80%;
    height: 80%;
    transform-style: preserve-3d;
}

.sensor_main img {
    width: 100%;
    height: auto;
    margin-top: -6px;
}

.knob {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    transform: translateZ(10px);
    transform-style: preserve-3d;
    // margin-top: 10px;
}

.knob svg {
    filter: drop-shadow(0 0px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0 0px 2px rgba(0, 0, 0, 0.12));
    position: absolute;
    width: 60%;
}

.knob svg path {
    transform-origin: center 55.87%;
    transform: translateZ(20px);
}

.knob svg text {
    color: #10264d;
    font-size: 44px;
    font-weight: 400;
}

@media only screen and (max-width: 500px) {
    .sensor {
        --sensor-size: 300px;
        --y-rotate: -6deg;
        --x-rotate: 3deg;
    }

    .sensor_header h2 {
        font-size: 18px;
    }

    .sensor_header svg {
        width: 57px;
    }
}
