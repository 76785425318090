.container {
    height: 100%;
}

.content {
    // Use 100vh as fallback
    height: 100vh;
    height: var(--app-height);
    display: flex;
    flex-direction: column;
}

.airQuality {
    flex-grow: 1;
}

.bar {
    flex-shrink: 0;
}
