@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/gradients';
@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/colors';
@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/layout';

.container {
    // height: 100%;
    color: $base-text-color-inverted;
    padding: spacing(5);
    padding-top: spacing(3);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    min-height: 660px;

    @include media-query(mobile) {
        padding: spacing(3);
    }

    @include media-query(500px) {
        min-height: 580px;
    }

    & > p {
        color: $base-text-color;
    }

    &.good,
    &.moderate,
    &.bad {
        animation: fadein 0.6s;
    }

    &.good {
        background: $gradient-status-good;
    }

    &.moderate {
        background: $gradient-status-moderate;
    }

    &.bad {
        background: $gradient-status-bad;

        .logo {
            path {
                fill: $base-text-color-inverted;
                @include media-query(mobile) {
                    fill: unset;
                }
            }
        }
    }

    &.error {
        padding: 0;
    }
}

.logo {
    margin: 0 auto;
    z-index: 10;
}

.title {
    margin: spacing(13, 1px) auto 0;
    text-align: center;
    z-index: 10;
    line-height: 1.23;
    max-width: 688px;
    width: 100%;
    @include media-query(mobile) {
        margin-top: spacing(7);
    }
}

.content {
    margin: auto;
    margin-bottom: 0;
    z-index: 10;
    max-width: 688px;
    width: 100%;

    & > * + * {
        margin-top: spacing(1);
    }
}

.roomName {
    margin-top: spacing(3, -2px);
    text-align: center;

    & + .title {
        margin-top: spacing(7, 6px);

        @include media-query(mobile) {
            margin-top: spacing(1, 5px);
        }
    }
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $base-text-color;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.sensorErrorContainer {
    height: var(--app-height);
    position: relative;
    background-color: #fff;
    overflow-x: hidden;
    font-weight: bold;
    font-size: 1.375em;
    color: #10264d;
}

.buildingInsightLogo {
    width: 120px;
    height: 50px;
    margin: 0 auto;
    position: relative;
    z-index: 50;
}

.errorTitle {
    margin-top: 148px;
    text-align: center;
    line-height: 1.23;
    font-size: 1.375em;
    position: relative;
    z-index: 50;
}

.buttonWrapper {
    margin: 54px auto 0;
    max-width: 327px;
    position: relative;
    z-index: 50;
}

.errorLink {
    background-color: #10264d;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: background-color 150ms ease;
    border-radius: 4px;
    padding: 14px 12px 13px;
    transition: background-color border-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    outline: none;
    text-decoration: none;
    font-size: 0.875em;
    font-weight: bold;
    border: 0;
    width: 100%;
    box-sizing: border-box;
}

.errorTopCloud {
    position: absolute;
    z-index: 0;
    top: 0px;
    right: -80px;
    width: auto;
    height: 165px;
}

.errorBottomCloud {
    position: absolute;
    z-index: 0;
    left: 80px;
    bottom: 0;
    width: 595px;
    height: 204px;
}
