.react-datepicker__time-container {
    width: 100px !important;
}

.react-datepicker__time-box {
    width: 100px !important;
}

.react-datepicker__day--selected {
    background-color: #2a4364 !important;
}

.react-datepicker__time-list-item--selected {
    background-color: #2a4364 !important;
}

.react-datepicker__input-container input {
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
    font-size: 24px;
    font-weight: 500;
    position: relative;
    padding: 0;
    padding-bottom: 4px;
    width: 100%;
}
