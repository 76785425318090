@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/colors';
@import 'node_modules/@unicaiot/unica-iot-gallery-airinsight/dist/styles/variables/layout';

.content {
    width: 100%;
    height: 100%;
    color: $base-text-color-inverted;
}

.form {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.input {
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
    font-size: 24px;
    font-weight: 500;
    position: relative;
    padding: 0;
    padding-bottom: spacing(1, -4px);
    width: 100%;

    &[type='date'],
    &[type='time'] {
        &::-webkit-calendar-picker-indicator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
            color: transparent;
            background: transparent;
        }
    }

    &::placeholder {
        font-weight: normal;
        color: rgba($color-white, 0.6);
    }

    &:focus {
        border: 0;
        outline: none;
        border-bottom: 1px solid $color-white;
    }
}

.wrapper {
    width: 100%;
}

.labelText {
    font-size: 12px;
    margin-bottom: spacing(1, 6px);
}

.label {
    display: flex;
    flex-direction: column;
}

.inputWrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;

    .input {
        border-bottom: 0;
        width: 100%;

        &:focus {
            border: 0;
        }
    }

    &:focus-within {
        border-bottom-color: $color-white;
    }
}

.inputPrefix {
    font-size: 24px;
    font-weight: 500;
    margin-right: spacing(1, -4px);
    flex-shrink: 0;
}

.disclaimer {
    opacity: 0.6;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    margin-top: spacing(1);
}

.formGroup {
    display: flex;
    flex-wrap: wrap;
    margin-top: spacing(5, -2px);

    .labelText {
        width: 100%;
    }

    .label {
        width: calc(50% - #{spacing(2.5)});

        &:nth-of-type(even) {
            margin-left: spacing(5);
        }
    }

    @include media-query(mobile) {
        flex-direction: column;
        flex-wrap: nowrap;

        .label {
            width: 100%;

            & + .label {
                margin-top: spacing(1, 5px);
            }

            &:nth-of-type(even) {
                margin-left: 0;
            }
        }
    }
}

.submitButton {
    margin-top: spacing(5, -2px);
}
